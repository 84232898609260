@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@layer utilities {
  .btn {
    padding: 0.75rem;
    border-radius: 10px;
  }
  .btn-primary {
    background-color: #00adb5;
    border: #00adb5 1px solid;
  }

  .btn-primary:hover {
    border: #00adb5 1px solid;
    color: #00adb5;
    background-color: transparent;
    transition: ease-in-out 0.5s;
  }

  .btn-noex-white {
    background-color: #eeeeee;
    border: #eeeeee 1px solid;
    color: #222831;
  }

  .btn-noex-white:hover {
    border: #eeeeee 1px solid;
    color: #eeeeee;
    background-color: transparent;
    transition: ease-in-out 0.5s;
  }

  .btn-primary-outline {
    border: #00adb5 1px solid;
    color: #00adb5;
  }
  .btn-primary-outline:hover {
    background-color: #00adb5;
    color: #fff;
    transition: ease-in-out 0.5s;
  }

  .btn-noex-white-outline {
    border: #eeee 1px solid;
    color: #eeee;
    background-color: transparent;
  }
  .btn-noex-white-outline:hover {
    background-color: #eee;
    color: #222831;
    transition: ease-in-out 0.5s;
  }
}

/* Scrollbar Track */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  border-radius: 100px !important;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: #393e46; /* Color of the track */
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #222831; /* Color of the handle */
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the handle on hover */
}
